import React from 'react';
import "./hero.css";
import bg from "../../assets/hero-image.png";
import { Container, Button, Stack } from 'react-bootstrap';

const Hero = () => {
    return (
        <div className='bg' style={{ backgroundImage: `url(${bg})` }}>
            <Container>
                <Stack className='hero-content'>
                    <h1 className='inverse'>Experienced General Contractor</h1>
                    <h2 className='inverse'>in Southern Maine</h2>
                    <p className='inverse pt-3 hero-paragraph'>
                        Property Essentials LLC has the expertise and resources to bring
                        your vision to life. Our comprehensive range of services includes
                        <strong> New Construction</strong>, <strong>Remodeling</strong>, and <strong>Home Additions</strong>.
                    </p>
                    <Button className="free-estimate-btn mt-4" variant="primary">
                        <h4 className='fw-bold my-2'>Free Estimate</h4>
                    </Button>
                </Stack>
            </Container>
        </div>
    )
}

export default Hero

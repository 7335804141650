import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "./photogrid.css";
import { Photo } from '../../components';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';

const PhotoGrid = ({ photos, itemsPerPage, isMultiPage }) => {
    const navigate = useNavigate();

    const handleButtonClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
      };

    const [currentPage, setCurrentPage] = useState(1);

    // Calculate total pages
    let totalPages = Math.ceil(photos.length / itemsPerPage);
    let photoArray = photos;
    if (!isMultiPage) {
        totalPages = 1
         photoArray = photos.slice(0, itemsPerPage);
    }

    // Function to paginate inventory items
    const getPaginatedItems = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return photos.slice(startIndex, endIndex);
    };

    // Function to handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        setCurrentPage(1); // Reset current page when inventoryData changes
    }, [photoArray]);

    return (
        <div>
            <Container className='inventory-cell-container'>
                <Row xs={1} sm={2} lg={3} xl={3}>
                    {getPaginatedItems().length > 0 ? (
                        getPaginatedItems().map((photo, index) => (
                            <Col className="inventory-list" key={photo.id}>
                                <Photo key={index} src={photo.src} alt={photo.alt} />
                            </Col>
                        ))
                    ) : (
                        <Col className='m-auto'>
                            <h5 className='no-items-message text-center'>No Photos currently available</h5>
                        </Col>
                    )}

                </Row>
                {/* Pagination controls */}
                {totalPages > 1 && (
                    <Row className="mt-5 pagination">
                        <Col className="text-center">
                            <Button variant="outline-secondary" className='mx-3' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                Previous
                            </Button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <Button variant="outline-secondary" className='mx-3' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                )}

                {/* See More Button */}
                {!isMultiPage && (
                    <Row className="justify-content-end">
                        <Col xs="auto">
                            <Link to="/gallery" onClick={() => handleButtonClick('/gallery')}>
                                <Button className="mt-4" variant="outline-secondary">
                                    <h6 className='my-1'>See More</h6>
                                </Button>   
                            </Link>
                        </Col>
                    </Row>               
                )}
            </Container>
        </div >
    )
}

export default PhotoGrid;
import React from 'react'
import { ContactForm, Hero, HomeHelmet, Header, GalleryComponent, Footer, Reviews } from '../../components';
import "./home.css";

const Home = () => {
  return (
    <div className='home'>
      <HomeHelmet />
      <Header />
      <Hero />
      <GalleryComponent itemsPerPage={6} isMultiPage={false}/>
      <Reviews/>
      <ContactForm variant={"home"}/>
      <Footer/>
    </div>

  )
}

export default Home
import React from 'react';
import "./contact-form.css";
import { Container, Stack, Row, Col, Image, Form, Button } from 'react-bootstrap';
import pin from '../../assets/social-img/pin.png';
import mail from "../../assets/social-img/mail.png";
import phone from "../../assets/social-img/phone.png";
import insta from "../../assets/social-img/instagram.png";
import bg from "../../assets/hero-image.png"

const ContactForm = ({ variant }) => {
    return (
        <Container>
            {variant !== "home" ? (
                <>
                    <h1>Contact Us</h1>
                    <Row className='shadow my-5' style={{ margin: 0 }} /* This margin overrides the margin that is automatically on row component - aligns ContactForm with FAQs*/>
                        <Col lg={7} md={12} className='py-5 px-5'>
                            <h4 className='fw-bold pb-4'>Get a Free Estimate</h4>
                            <EstimateForm />
                        </Col>
                        <ContactInfo />
                    </Row>
                </>
            ) : (
                <>
                    <Row className='shadow my-5' style={{ margin: 0 }} /* This margin overrides the margin that is automatically on row component - aligns ContactForm with FAQs*/>
                        <Col className='py-5 px-5'>
                            <h2 className='fw-bold pb-4'>Get a Free Estimate</h2>
                            <EstimateForm />
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    )
}


const ContactInfo = () => {
    const socialData = [
        ['Wells, Maine', pin, "https://www.google.com/maps/place/Property+Essentials+LLC/@43.3656579,-70.6043789,9z/data=!4m6!3m5!1s0x4cb2ad77bddb1f29:0x6c91fd3203cd661f!8m2!3d43.365702!4d-70.605606!16s%2Fg%2F11bxjc7prd?entry=ttu"],
        ['Tom@propertyessentialsllc.com', mail, "mailto:tom@propertyessentialsllc.com"],
        ['(207) 370-8557', phone, "tel:207-370-8557"],
        ['propertyessentialsllc', insta, "https://www.instagram.com/propertyessentialsllc/"]
    ];

    return (
        <Col lg={5} md={12} className='contact-info d-flex py-5 px-5' style={{ backgroundImage: `url(${bg})`, backgroundPosition: 'center' }}>
            <Stack className='justify-content-center' gap={5}>
                {socialData.map(([name, image, link]) => (
                    <a href={link}>
                        <Stack direction='horizontal'>
                            <Image src={image} className='social-icon' />
                            <h6 className='social-name inverse'>{name}</h6>
                        </Stack>
                    </a>
                ))}
            </Stack>
        </Col>
    );
};

const EstimateForm = () => {
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || form.name.value !== '') {
            event.preventDefault();
            event.stopPropagation();
            console.log("Invalid");
        } else {
            event.preventDefault();
            const formData = new FormData(form);
            try {
                const response = await fetch('https://pe.rockycoastlabs.xyz/backend/sendEmail.php', {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    alert("Email sent successfully! We will reach out to you as soon as possible. Thank You!");

                } else {
                    alert("An error has occured while sending the email. Please reach out to us using the contact info provided on the page. Thank you.");

                }
            } catch (error) {
                console.error('Error sending email:', error);
            }
        }
        window.location.reload();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className='py-3'>
                <Form.Control type="text" name="customer-name" autoComplete="on" placeholder="Name" required />
            </Form.Group>
            <Form.Group className='py-3'>
                <Form.Control type="text" name="email" autoComplete="on" placeholder="Email" required />
            </Form.Group>
            <Form.Group className='py-3'>
                <Form.Control as="textarea" rows={5} name="message" placeholder="Message" required />
            </Form.Group>

            {/* Honeypot field */}
            <Form.Group className="mb-3 not-today">
                <Form.Label><h5>Name</h5></Form.Label>
                <Form.Control type="text" name="name" autoComplete="off" />
            </Form.Group>

            <div className="d-flex justify-content-end mt-4">
                <Button className='send-btn' type='submit'>
                    <h5 className='fw-bold my-1 mx-4'>Send</h5>
                </Button>
            </div>
        </Form>
    );
};


export default ContactForm

import React from 'react';
import { Container } from 'react-bootstrap';
import { Footer, Header, ServiceContainer } from '../../components';
import "./services.css";

const Services = () => {
  return (
    <>
    <Container className='services'>
        <Header />
        <h1 className='pb-3'>Services</h1>
        <ServiceContainer className="pt-5"/>
        
    </Container>
    <Footer/>
    </>
  )
}

export default Services

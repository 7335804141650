import React from 'react';
import Container from 'react-bootstrap/Container';
import { PhotoGrid } from '../../components';
import fire from "../../assets/gallery-photos/gallery1.JPG";
import bath from "../../assets/gallery-photos/gallery2.JPG";
import vanity from "../../assets/gallery-photos/gallery3.JPG";
import stairs from "../../assets/gallery-photos/gallery4.JPG";
import ceiling from "../../assets/gallery-photos/gallery5.JPG";
import garage from "../../assets/gallery-photos/gallery6.JPG";
import shower from "../../assets/gallery-photos/gallery7.JPG";
import sink from "../../assets/gallery-photos/gallery8.JPG";
import house from "../../assets/gallery-photos/gallery9.JPG";


const photos = [
  { src: fire, alt: 'Photo 1' },
  { src: bath, alt: 'Photo 2' },
  { src: vanity, alt: 'Photo 3' },
  { src: stairs, alt: 'Photo 4' },
  { src: ceiling, alt: 'Photo 5' },
  { src: garage, alt: 'Photo 6' },
  { src: shower, alt: 'Photo 7' },
  { src: sink, alt: 'Photo 8' },
  { src: house, alt: 'Photo 9' },
  { src: house, alt: 'Photo 9' },
  { src: house, alt: 'Photo 9' },
  { src: house, alt: 'Photo 9' },
  { src: house, alt: 'Photo 9' },
  { src: house, alt: 'Photo 9' },
  { src: house, alt: 'Photo 9' },
  { src: house, alt: 'Photo 9' },


];

const GalleryComponent = ({ itemsPerPage, isMultiPage }) => {
  return (
    <div>
      <Container className='mt-5 mb-5'>
        <h1 className='mb-5'>Gallery</h1>
        <PhotoGrid photos={photos} itemsPerPage={itemsPerPage} isMultiPage={isMultiPage} />      
      </Container>

    </div>
  )
}

export default GalleryComponent

import React from 'react';
import { Header, ContactForm, FAQs, Footer } from '../../components';
import "./contact.css"

const Contact = () => {
  return (
    <div className='contact'>
      <Header />
      <ContactForm/>
      <FAQs/>
      <Footer/>
    </div>
  )
}

export default Contact

import React from 'react';
import { useNavigate } from "react-router-dom";
import "./footer.css";
import { Container, Image, Row, Col, Nav, Stack } from 'react-bootstrap';
import logo from "../../assets/logo-inverse.png";

const Footer = () => {
    const navigate = useNavigate();

    const handleNavItemClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <div className='footer'>
            <Container>
                <Row className='footer-padding'>
                    <Col lg={4} className='d-flex pt-lg-0 pt-5 order-lg-1 order-3 '>
                        <Stack className='align-items-lg-start align-items-center'>
                            <Nav.Link onClick={() => handleNavItemClick('/')}>
                                <Image src={logo} />
                            </Nav.Link>
                            <div className='pt-3 copyright'>© Property Essentials LLC., Rights Reserved</div>
                        </Stack>
                    </Col>
                    <Col lg={4} className='d-none d-lg-flex order-lg-2 order-1'>
                        <Stack gap={4} className='justify-content-start text-center mx-4'>
                            <h5 className='inverse'>Open Hours</h5>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <p className='inverse'>Monday - Friday: </p>
                                    <p className='inverse'>11am - 5pm</p>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p className='inverse'>Sunday - Saturday: </p>
                                    <p className='inverse'>Closed</p>
                                </div>
                            </div>
                        </Stack>
                    </Col>
                    <Col lg={4} className='order-lg-3 order-2'>
                        <Stack gap={3} className='text-center text-lg-end'>
                            <Nav.Link onClick={() => handleNavItemClick('/')}>
                                <h5 className='inverse'>Home</h5>
                            </Nav.Link>
                            <Nav.Link onClick={() => handleNavItemClick('/services')}>
                                <h5 className='inverse'>Services</h5>
                            </Nav.Link>
                            <Nav.Link onClick={() => handleNavItemClick('/gallery')}>
                                <h5 className='inverse'>Gallery</h5>
                            </Nav.Link>
                            <Nav.Link onClick={() => handleNavItemClick('/contact')}>
                                <h5 className='inverse'>Contact</h5>
                            </Nav.Link>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer

import React from 'react';
import "./header.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav, Navbar, Container, Image } from "react-bootstrap";
import { SocialHeader } from '../../components';
import logo from "../../assets/logo.png";

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleNavItemClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <SocialHeader />
            <Navbar className='header py-4' collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand>
                        <Nav.Link to="/" onClick={() => handleNavItemClick('/')}>
                            <Image src={logo} />
                        </Nav.Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav className='text-end' activeKey={location.pathname}>
                            <Nav.Item className='mx-xl-3 mx-lg-2'>
                                <Nav.Link to="/" onClick={() => handleNavItemClick('/')}>
                                    <h3>Home</h3>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='mx-xl-3 mx-lg-2'>
                                <Nav.Link to="/services" onClick={() => handleNavItemClick('/services')}>
                                    <h3>Services</h3>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='mx-xl-3 mx-lg-2'>
                                <Nav.Link to="/gallery" onClick={() => handleNavItemClick('/gallery')}>
                                    <h3>Gallery</h3>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='mx-xl-3 mx-lg-2'>
                                <Nav.Link to="/contact" onClick={() => handleNavItemClick('/contact')}>
                                    <h3>Contact</h3>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header


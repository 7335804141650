import React from 'react';
import "./social-header.css";
import { Nav, Image, Container } from "react-bootstrap";
import mail from "../../assets/social-img/mail.png";
import phone from "../../assets/social-img/phone.png";
import insta from "../../assets/social-img/instagram.png"

const SocialHeader = () => {
    return (
        <div className='social-header py-2'>
            <Container>
                <Nav className="justify-content-end ">
                    <Nav.Item>
                        <Nav.Link className='d-flex align-items-center' href='tel: (207) 370-8557'>
                            <Image src={phone} className='social-logo' fluid />
                            <p className='inverse social-text desktop-only'>(207) 370-8557</p>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='d-flex align-items-center' href='mailto:tom@propertyessentialsllc.com'>
                            <Image src={mail} className='social-logo' fluid />
                            <p className='inverse social-text desktop-only'>tom@propertyessentialsllc.com</p>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='d-flex align-items-center' href="https://www.instagram.com/propertyessentialsllc/">
                            <Image src={insta} className='social-logo' fluid />
                            <p className='inverse social-text desktop-only'>propertyessentialsllc</p>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Container>
        </div>
    )
}

export default SocialHeader

import React from 'react';
import { Helmet } from 'react-helmet-async';

const HomeHelmet = () => { 
  const socialLinks = {
    facebook: '',
    instagram: '',
    youtube: '',
  };

  return (
    <div>
      <Helmet>
        <title>Property Essentials - Home</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />

        <meta property="og:url" content="" />
        <meta property="og:title" content="Property Essentials" />
        <meta property="og:description" content="" />
        {/* <meta property="og:image" content={} /> */}

        {/* Facebook */}
        <meta property="article:publisher" content={socialLinks.facebook} />
        {/* Instagram */}
        <meta property="instagram:site" content={socialLinks.instagram} />
        {/* YouTube */}
        <meta property="youtube:channel" content={socialLinks.youtube} />
      </Helmet>
    </div>
  )
}

export default HomeHelmet

import React from 'react';
import "./reviews.css";
import google from "../../assets/social-img/google.png";
import star from "../../assets/social-img/star.png";
import { Button, Col, Container, Stack, Row } from "react-bootstrap";
import ShowMoreText from "react-show-more-text";


const reviews = [
    {
        name: "Joanne Sudol",
        stars: 5,
        text: "We had the pleasure for Property Essentials to build us a composite deck that’s absolutely beautiful. Tom, Jerry, and Dan were professional, courteous, knowledgeable and accommodating craftsmen. They came and worked until the deck was done on time and on budget. From start to finish it was a great experience. We highly recommend them.",
        link: "https://www.google.com/maps/contrib/116192148580760737828/place/ChIJKR_bvXetskwRH2bNAzL9kWw/@43.365702,-70.6262054,14z/data=!4m6!1m5!8m4!1e1!2s116192148580760737828!3m1!1e1?hl=en-US&entry=ttu"
    },
    {
        name: "Pat Beaver",
        stars: 5,
        text: "If you are looking for a contractor to complete a project at your home look no further than Property Essentials and Tom Kinney. Tom and his crew just finished an attic space over our garage and we couldn’t be happier. From the get go Tom was very proactive in keeping us in the loop from the moment we contacted him initially until the final nail was driven.  When you called, he answered or returned your call quickly, the same with texts and emails.  His crew of subcontractors was excellent. Everyone that crossed our threshold was skilled, no nonsense and polite.  The room was exactly what we wanted and then some.  Both my husband and I would highly recommend Property Essentials and Tom Kinney.",
        link: "https://g.co/kgs/44dYAXV"
    },
    {
        name: "Scotty Mac",
        stars: 5,
        text: "After seeing some damage to the roof of our home in York Beach I began the process of calling roofers to get an evaluation and work done as appropriate.  I was shocked at how many rejections I got and how many simply don’t answer or return calls.  One told me straight out that he wouldn’t do work in York because the customers are to finicky and cheap.  I finally got to Tom at Property Essentials and he went out of his way to come out and look and make a determination on the status of the roof.  Once we determined that it should be replaced within days he had his team on it and in a matter of hours we had a beautiful new roof.  I was amazed when I went up to see how the work was progressing and they had cleaned up so well it was as if no one was there.  Fantastic job!  I will be calling Property essentials first for all my project needs!",
        link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNSOWJMblRREAE!2m1!1s0x0:0x6c91fd3203cd661f!3m1!1s2@1:CIHM0ogKEICAgICR9bLnTQ%7CCgwI4L_AoQYQ0LaX6wI%7C?hl=en-US"
    }
];

const Reviews = () => {
    return (
        <div className='review-row py-5'>
            <Container>
                <Row>
                    <h1 className='mb-5'>Reviews</h1>
                    {reviews.map(review => (
                        <Col lg={4} className='my-3'>
                            <div className='review-container pt-4 pb-2 px-4'>
                                <a href={review.link}>
                                    <Stack direction="horizontal" className='pb-3 d-flex justify-content-between'>
                                        <h5 className='m-0'>{review.name}</h5>
                                        <img src={google} alt="Google Icon" />
                                    </Stack>
                                </a>
                                <Stack direction="horizontal" className='d-flex align-items-center'>
                                    <h3 className='m-0 me-3'>{review.stars}</h3>
                                    <Stack gap={1} direction="horizontal">
                                        {[...Array(review.stars)].map((_, index) => (
                                            <img key={index} src={star} alt=""/>
                                        ))}
                                    </Stack>
                                </Stack>

                                <ShowMoreText
                                    lines={5}
                                    more=" Show More"
                                    less="Show Less"
                                    anchorClass='show-btn'
                                    className='mt-3 mb-2'
                                >
                                    <p >{review.text}</p>
                                </ShowMoreText>

                            </div>
                        </Col>
                    ))}
                    <a href='https://www.google.com/search?q=property+essentials+llc&sca_esv=7065ff04b747ac94&sxsrf=ACQVn0-ufsWuk5gwOU0R2sDgg3_4bKPM_A%3A1714600999382&ei=J7wyZrrmFuie5NoPn9KHwA8&ved=0ahUKEwj6lf-7uu2FAxVoD1kFHR_pAfgQ4dUDCBE&uact=5&oq=property+essentials+llc&gs_lp=Egxnd3Mtd2l6LXNlcnAiF3Byb3BlcnR5IGVzc2VudGlhbHMgbGxjMgoQIxiABBgnGIoFMgQQIxgnMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeMggQABiABBiiBDIIEAAYgAQYogRI7B9QAFjtHnABeAGQAQCYAdMBoAHGFKoBBjkuMTMuMbgBA8gBAPgBAZgCGKAChRXCAgsQABiABBiRAhiKBcICERAuGIAEGLEDGNEDGIMBGMcBwgILEAAYgAQYsQMYgwHCAggQABiABBixA8ICBRAuGIAEwgIKEAAYgAQYQxiKBcICEBAuGIAEGLEDGEMYgwEYigXCAgoQLhiABBhDGIoFwgIREAAYgAQYkQIYsQMYgwEYigXCAgsQLhiABBjHARivAcICExAAGIAEGLEDGIMBGBQYhwIYyQPCAg4QLhiABBjHARiOBRivAcICDRAAGIAEGLEDGEMYigXCAhAQABiABBixAxhDGIMBGIoFwgIFEAAYgATCAhAQABiABBixAxhDGMkDGIoFwgILEAAYgAQYkgMYigXCAhAQABiABBixAxiDARgUGIcCwgILEC4YgAQYsQMYgwHCAgoQABiABBgUGIcCwgIKEAAYgAQYsQMYCsICDRAAGIAEGLEDGIMBGArCAgcQABiABBgKwgIIEAAYFhgeGA_CAgwQLhjRAxgWGMcBGB7CAgoQABgWGAoYHhgPwgILEAAYgAQYhgMYigWYAwCSBwY4LjE1LjGgB6P-AQ&sclient=gws-wiz-serp#lrd=0x4cb2ad77bddb1f29:0x6c91fd3203cd661f,1,,,,' 
                    className='d-flex justify-content-end'>
                        <Button className="mt-4" variant="outline-secondary">
                            <h6 className='my-1'>See More</h6>
                        </Button>
                    </a>
                </Row>
            </Container>
        </div>
    )
}

export default Reviews

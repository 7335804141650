import React from 'react';
import { GalleryComponent, Header, Footer } from '../../components';
import "./gallery.css";

const Gallery = () => {
  return (
    <div className='gallery'>
      <Header />
      <GalleryComponent itemsPerPage={9} isMultiPage={true}/>
      <Footer />
    </div>
  )
}

export default Gallery

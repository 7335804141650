import React from 'react';
import { Stack, Col, Image, Row } from 'react-bootstrap';
import "./service.css";
import house from "../../assets/gallery-photos/gallery9.JPG";
import addition from "../../assets/gallery-photos/gallery10.JPG";
import remodel from "../../assets/gallery-photos/gallery11.JPG";

// Define the Service component
const Service = ({ selectedService, isLeftHand }) => {
    
    return (
        <Row className="service-container pt-5 pb-5">
            {isLeftHand ? (
                <>
                    <Col md={6} className="order-md-1 pe-5 d-flex mb-5">
                        <Stack className='justify-content-center' direction="vertical">
                            <h3 className='mb-4'>{selectedService[0]}</h3>
                            <p>{selectedService[2]}</p>
                        </Stack>
                    </Col>
                    <Col md={6} className="order-md-2 ps-5">
                        <div className='image-container'>
                            <Image className='service-img'src={selectedService[1]} fluid />
                        </div>                    
                    </Col>
                </>
            ) : (
                <>
                    <Col md={6} className="order-md-1 order-2 pe-5">
                        <div className='inverse image-container'>
                            <Image className='service-img' src={selectedService[1]} fluid />
                        </div>
                    </Col>
                    <Col md={6} className="middle-section-text d-flex order-md-2 order-1 ps-md-5 mb-5">
                        <Stack className='justify-content-center' direction="vertical">
                            <h3 className='mb-4'>{selectedService[0]}</h3>
                            <p>{selectedService[2]}</p>
                        </Stack>
                    </Col>
                </>
            )}
        </Row>
    );
}

// Define the Services component
const ServiceContainer = () => {
    const services = [
        [
            "New Construction", 
            house, 
            "Building your dream home from the ground up? Our new construction services offer personalized solutions for every step of the process. From planning to completion, trust us to turn your vision into reality."
        ],
        [
            "Home Additions", 
            addition, 
            "Looking to add more space and value to your home? Our custom home addition services are tailored to your needs, whether it's creating a cozy family room, expanding your living area or adding a dock to yard."
        ],
        [
            "Remodeling", 
            remodel, 
            "From kitchens and bathrooms to bedrooms and garages, our remodeling services cover all aspects of home improvement. Let us revitalize your spaces with expert craftsmanship and personalized solutions."
        ],
    ];
    return (
        <>
            <Service selectedService={services[0]} isLeftHand={true} />
            <Service selectedService={services[1]} isLeftHand={false} />
            <Service selectedService={services[2]} isLeftHand={true} />
        </>
    );
}

export { Service, ServiceContainer };
